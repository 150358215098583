import React, { Fragment } from 'react';
import {
    useTranslate,
    BulkDeleteButton,
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    ChipField,
    DateField,
    Filter,
    TextInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';
import CustomSearchInput from '../components/CustomSearchInput';
import BoxitChipField from '../components/BoxitChipField';
import UserReferenceField from '../components/UserReferenceField';
import { StorageStatus } from '../constants';
import CountryField from '../components/CountryField';
import MoneyField from '../components/MoneyField';
import BoxitPagination from '../components/BoxitPagination';
import WarehouseField from './components/WarehouseField';
import StorageTypeField from '../components/StorageTypeField';
import StorageRecordField from '../components/StorageRecordField';
import BulkEdit from './components/BulkEdit';
import CreateRequest from './components/CreateRequest';
import DamageField from '../components/DamageField';
import { ListProps, Storage, StorageType } from '../types';
import { FunctionField } from 'react-admin';
import { StorageRecordStatus } from '../constants';
import { ArrayInput } from 'react-admin';
import { SelectArrayInput } from 'react-admin';
import NameField from '../requests/components/NameField';
import NameFieldList from '../requests/components/NameFieldList';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const BulkActionButtons = props => (
    <Fragment>
        <CreateRequest {...props} />
        <BulkEdit {...props} />
        <BulkDeleteButton undoable={false} {...props} />
    </Fragment>
);

const QuickFilter = ({ source, label, defaultValue }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const StorageFilter = props => {
    return (
        <Filter variant="outlined" {...props}>
            <CustomSearchInput alwaysOn />
            <ReferenceInput
                source="partnerId"
                reference="partners"
                resource="partners"
                label="Partner"
            >
                <SelectInput />
            </ReferenceInput>

            <SelectArrayInput
                style={{ minWidth: '10rem' }}
                source="status"
                choices={Object.keys(StorageStatus).map(c => ({
                    id: c,
                    name: c,
                }))}
            />

            <QuickFilter source="isPart" label="Parts" defaultValue={true} />

            <ReferenceInput
                source="storagetypeId"
                reference="storage-types"
                resource="storage-types"
                label="Type"
            >
                <AutocompleteInput
                    optionText={(choice: StorageType) =>
                        choice.code ? `${choice.code} ${choice.name}` : ''
                    }
                    matchSuggestion={(
                        filterValue: string,
                        choice: StorageType
                    ) =>
                        choice.code.match(new RegExp(filterValue, 'i')) ||
                        choice.name.match(new RegExp(filterValue, 'i'))
                    }
                    shouldRenderSuggestions={(val: string) =>
                        val.trim().length > 1
                    }
                />
            </ReferenceInput>

            <ReferenceInput
                source="userId"
                reference="users"
                resource="users"
                label="Customer"
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText={user => (user.name ? user.name : user.email)}
                />
            </ReferenceInput>

            <ReferenceInput
                source="orderId"
                reference="orders"
                resource="orders"
                label="Order"
            >
                <TextInput source="orderId" />
            </ReferenceInput>

            <ReferenceInput
                source="countryId"
                reference="country"
                resource="country"
                label="Country"
            >
                <SelectInput />
            </ReferenceInput>

            <TextInput source="parentId" />
        </Filter>
    );
};

const LocationField = ({
    label,
    record,
    ...props
}: {
    label: string;
    record?: Storage;
    props?: any;
}) =>
    record ? (
        <StorageRecordField label={label} storageId={record.id} {...props} />
    ) : null;

const StorageList: React.FC<ListProps<Storage>> = ({
    permissions = [],
    ...props
}) => (
    <List
        title="Storages"
        bulkActionButtons={<BulkActionButtons />}
        filters={<StorageFilter />}
        pagination={<BoxitPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <TextField source="code" />
            {/*<StorageTypeField label="Type" />*/}
            <FunctionField
                label="Type"
                render={record => {
                    return (
                        <div>
                            <span style={{}}>{record.storagetype.code}</span>
                            {record.isPart && <Chip label="part" />}
                        </div>
                    );
                }}
            />
            <DamageField source="damage" label="Damage" />
            {/* <TextField source="name" label="Name" /> */}
            <NameFieldList label="Name" />

            <TextField source="qty" />
            <TextField source="size" label="CBM" />
            <FunctionField
                label="Total size"
                render={record =>
                    record.qty !== undefined && record.size !== undefined
                        ? record.qty * record.size
                        : ''
                }
            />
            <TextField source="sizeEntered" label="Actual CBM Size (test)" />
            <TextField source="consumedSize" label="Consumed Size (test)" />
            <TextField source="length" label="Length" />
            <TextField source="height" label="Height" />
            <TextField source="width" label="Width" />
            <UserReferenceField label="Customer" />
            {/*<CountryField />*/}
            <FunctionField
                label="Country"
                render={record => {
                    return record.countryId === 1
                        ? 'UAE'
                        : record.countryId === 2
                        ? 'Kuwait'
                        : 'KSA';
                }}
            />
            <DateField locales="en-IN" source="from" />
            <DateField locales="en-IN" source="to" />
            {/*<ReferenceField
                label="Order"
                source="orderId"
                reference="orders"
                link="show"
            >
                <TextField source="id" />
                </ReferenceField>*/}
            <FunctionField
                label="Order"
                render={record => {
                    return (
                        <div
                            style={{
                                width: '8rem',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <button
                                style={{
                                    cursor: 'pointer',
                                    color: '#1890ff',
                                    outline: 'none',
                                }}
                                onClick={() => {
                                    window.location.href = `/#/orders/${record.orderId}/show`;
                                }}
                            >
                                {record.orderId}
                            </button>
                        </div>
                    );
                }}
            />
            <BoxitChipField record source="status" list="storage" />
            {/*permissions.indexOf('partner') === -1 && (
                <WarehouseField label="Warehouse" />
            )*/}
            {permissions.indexOf('partner') === -1 && (
                <FunctionField
                    label="Warehouse"
                    render={record => {
                        const storageRecord =
                            record &&
                            record.storagerecords &&
                            !!record.storagerecords.length &&
                            record.storagerecords
                                .filter(
                                    i => i.status === StorageRecordStatus.in
                                )
                                .find(i => !!i);
                        return storageRecord ? (
                            <button
                                style={{
                                    cursor: 'pointer',
                                    color: '#1890ff',
                                    outline: 'none',
                                }}
                                onClick={() => {
                                    window.location.href = `/#/warehouses/${storageRecord.warehouseId}/show`;
                                }}
                            >
                                {storageRecord.warehouse.name}
                            </button>
                        ) : null;
                    }}
                />
            )}
            {/*permissions.indexOf('partner') === -1 && (
                <LocationField label="Locations&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
            )*/}
            {permissions.indexOf('partner') === -1 && (
                <FunctionField
                    label="Locations"
                    render={record => {
                        return (
                            <div
                                style={{
                                    width: '8rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {record.storagerecords
                                    .filter(
                                        storageRecord =>
                                            storageRecord.orderId &&
                                            storageRecord.storageId &&
                                            storageRecord.status ===
                                                StorageRecordStatus.in
                                    )
                                    .map(storageRecord => {
                                        return storageRecord.location ? (
                                            <button
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#1890ff',
                                                    outline: 'none',
                                                }}
                                                onClick={() => {
                                                    window.location.href = `/#/locations/${storageRecord.locationId}/show`;
                                                }}
                                            >
                                                {storageRecord.location.path}
                                            </button>
                                        ) : null;
                                    })}
                            </div>
                        );
                    }}
                />
            )}
            {permissions.indexOf('partner') === -1 && (
                <EditButton color="secondary" style={{ padding: 0 }} />
            )}
            <ShowButton style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

StorageList.defaultProps = {
    resource: 'storages',
    location: { search: '' },
};

export default StorageList;
