// export default NameField;

import React from 'react';

interface NameFieldProps {
    record?: {
        storage?: {
            name?: string;
            storagetype?: {
                name?: string;
            };
        };
    };
    label?: string; // Add label to the props
}

const NameField: React.FC<NameFieldProps> = ({ record }) => {
    if (!record || !record.storage) return null;

    const name = record.storage.name || record.storage.storagetype?.name || '';
    return <span>{name}</span>;
};

export default NameField;
