// export default NameField;

import React from 'react';

interface NameFieldListProps {
    record?: {
        name?: string;
        storagetype?: {
            name?: string;
        };
    };
    label?: string; // Add label to the props
}

const NameFieldList: React.FC<NameFieldListProps> = ({ record }) => {
    if (!record || !record.storagetype) return null;
    const name = record.name || record.storagetype?.name || '';
    return <span>{name}</span>;
};

export default NameFieldList;
