import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    BooleanField,
    DateField,
    ReferenceField,
    TextField,
    ChipField,
} from 'react-admin';
import {
    Chip,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@material-ui/core';
import Money from '../../components/Money';
import StorageTypeField from '../../components/StorageTypeField';
import BoxitChipField from '../../components/BoxitChipField';
import UserReferenceField from '../../components/UserReferenceField';
import DamageField from '../../components/DamageField';
import StorageLocation from './StorageLocation';
import feathersClient from '../../rest/feathersClient';

const StorageSummary = props => {
    const { record, permissions = [] } = props;
    const [parts, setParts] = useState([]);
    const generateSurvey = () => {
        const surveys = feathersClient.service('storages');
        surveys
            .find({
                query: { parentId: record.id },
            })
            .then(res => {
                setParts(res.data);
            })
            .catch(err => {});
    };

    useEffect(() => {
        generateSurvey();
    }, [record]);
    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.code}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {/* <ChipField record={record} source="status" /> */}
                            <BoxitChipField
                                record={record}
                                source="status"
                                list="storage"
                            />
                        </TableCell>
                    </TableRow>
                    {permissions.indexOf('partner') === -1 && (
                        <TableRow>
                            <TableCell>Location</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <StorageLocation record={record} />
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <StorageTypeField
                                record={record}
                                label="Type"
                                {...props}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Quantity On Hand</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.qty}
                        </TableCell>
                    </TableRow>
                    {record.isPart && (
                        <TableRow>
                            <TableCell>Parent</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.parentId && (
                                    <ReferenceField
                                        {...props}
                                        source="parentId"
                                        reference="storages"
                                        link="show"
                                    >
                                        <TextField source="id" />
                                    </ReferenceField>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Customer</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField {...props} label="Customer" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.name || record.storagetype?.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.description}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Room</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.roomId && (
                                <ReferenceField
                                    {...props}
                                    source="roomId"
                                    reference="rooms"
                                    link="show"
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Damages</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DamageField record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Packed by Customer</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <BooleanField
                                record={record}
                                source="customerPacked"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Actual CBM Size (test)</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.sizeEntered
                                ? `${record.sizeEntered} CBM`
                                : `-`}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Consumed Size (test)</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.consumedSize
                                ? `${record.consumedSize} CBM`
                                : `-`}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Size</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.size} CBM
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total size</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.qty !== undefined &&
                            record.size !== undefined
                                ? record.qty * record.size
                                : ''}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Length</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.length}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Height</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.height}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Width</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.width}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Validity</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.from && (
                                <span>
                                    <DateField
                                        locales="en-IN"
                                        record={record}
                                        source="from"
                                    />
                                    &nbsp; to &nbsp;
                                    <DateField
                                        locales="en-IN"
                                        record={record}
                                        source="to"
                                    />
                                </span>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Country</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.countryId}
                        </TableCell>
                    </TableRow>
                    {permissions.indexOf('partner') === -1 && (
                        <TableRow>
                            <TableCell>Warehouse</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.warehouse}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Order</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.orderId && (
                                <ReferenceField
                                    {...props}
                                    label="Order"
                                    source="orderId"
                                    reference="orders"
                                    link="show"
                                >
                                    <TextField source="id" />
                                </ReferenceField>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="Partner"
                                record={record}
                                source="partnerId"
                                reference="partners"
                                basePath="/partners"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    {parts.length > 0 ? (
                        <TableRow>
                            <TableCell>Number of parts</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <button
                                    style={{
                                        cursor: 'pointer',
                                        color: '#1890ff',
                                        outline: 'none',
                                    }}
                                    onClick={() => {
                                        window.location.href = `/#/storages?displayedFilters=%7B"parentId"%3Atrue%7D&filter=%7B"parentId"%3A"${record.id}"%7D&order=DESC&page=1&perPage=10&sort=id`;
                                    }}
                                >
                                    {parts.length}
                                </button>
                            </TableCell>
                        </TableRow>
                    ) : null}
                </TableBody>
            </Table>
        </div>
    );
};

StorageSummary.propTypes = {
    record: PropTypes.object,
};

StorageSummary.defaultProps = {
    record: {},
};

export default StorageSummary;
